import React from "react";
import { styled } from "goober";

import BlitzLogo from "@/inline-assets/blitz-logo.svg";
import { TimeRelative } from "@/shared/Time.jsx";
import { devDebug } from "@/util/dev.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useLazyTooltip } from "@/util/tooltip.mjs";
import useNativeClick from "@/util/use-native-click.mjs";

const Container = styled("div")`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled("div")`
  width: 600px;
  svg {
    display: block;
    width: var(--sp-8);
    height: calc(var(--sp-10) * 2);
  }
  p {
    margin-bottom: 1rem;
    color: var(--shade1);

    &:first-of-type,
    b {
      color: var(--shade0);
    }
  }
`;

const t0 = Date.now() - 60 * 1000;

function Dummy() {
  const route = useRoute();
  const fn = () => devDebug("hello!");
  const ref = useNativeClick(fn);
  const tooltipRef = useLazyTooltip(() => {
    return "example tooltip";
  });

  /* eslint-disable i18next/no-literal-string */
  return (
    <Container>
      <Content>
        <BlitzLogo />
        <p>
          This is a dummy route with a dummy component, for testing purposes
          only! Feature flags can add routes and load before initializing the
          first route.
        </p>
        <p ref={ref}>
          <a ref={tooltipRef} href="/">
            Root
          </a>
          <span> | </span>
          <TimeRelative date={t0} />
        </p>
        <p>
          {route.currentPath}
          <span> | </span>
          <a href="/dummy/1">1</a>
          <span> - </span>
          <a href="/dummy/2">2</a>
          <span> - </span>
          <a href="/dummy/3">3</a>
        </p>
      </Content>
    </Container>
  );
  /* eslint-enable i18next/no-literal-string */
}

export function meta() {
  return {
    title: [null, "Dummy"],
    description: [null, "Dummy text."],
  };
}

export default Dummy;
