import * as Dummy from "@/feature-dummy/Dummy.jsx";
import routes, { appRoutes } from "@/routes/routes.mjs";

const dummyRoute = {
  path: /^\/dummy/,
  component: Dummy, // "feature-dummy/Dummy.jsx"
};

const appDummyRoute = {
  path: /^\/app-dummy/,
  component: Dummy, // "feature-dummy/Dummy.jsx"
};

export const featureDummy = {};

export function setup() {
  featureDummy.didSetup = true;
  routes.push(dummyRoute);
  routes.push(appDummyRoute);
  appRoutes.push(appDummyRoute);
}

export function teardown() {
  featureDummy.didTeardown = true;
  routes.splice(routes.indexOf(dummyRoute), 1);
  routes.splice(routes.indexOf(appDummyRoute), 1);
  appRoutes.splice(appRoutes.indexOf(appDummyRoute), 1);
}
