import { useLayoutEffect, useRef } from "react";

/**
 * Why is this needed? In the rare use case that a link needs to also
 * have a click handler attached, React's synthetic events don't play nicely
 * with how we're hijacking link clicks.
 *
 * To make this slightly easier, this hook manages the lifecycle of native
 * event handling.
 */
export default function useNativeClick<T extends HTMLElement>(
  listener: (e: MouseEvent) => void,
) {
  const ref = useRef<T>();

  useLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener("click", listener);
    return () => {
      if (!ref.current) return;
      ref.current.removeEventListener("click", listener);
    };
  }, [ref.current, listener]);

  return ref;
}
